import React from 'react';

const TopBannerComponent: React.FC = () => {  return (
  <div className='TopBanner'>
    
    <div className="box">
      <h1>Holbæk Taekwondoklub</h1>
      <img src='collage.png' />
      <h1 style={{backgroundColor: "#596"}}> VI HAR DESVÆRRE IKKE PLADS TIL FLERE UNDER 18 ÅR</h1>
      <p>Der nu lukket for tilmeldinger for frivillige under 18år - men stadig ledige pladser til frivilige over 18år!</p>
      <img src='collage.png' alt="" />
    </div>
    
  </div>
  );
};

export default TopBannerComponent;
